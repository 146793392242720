<template>
<div>
</div>
    

</template>
<script>
import { ZoomMtg } from '@zoomus/websdk';
ZoomMtg.setZoomJSLib('node_modules/@zoomus/websdk/dist/lib', '/av');
ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.1/lib', '/av'); 
ZoomMtg.preLoadWasm();

ZoomMtg.prepareWebSDK();

var API_KEY = "fz4olszOTleCiom5QkJUiw";
var API_SECRET = "KU82Q4GvcBzSBsnx5Ydh5zbrp0DIBWiawknd";

export default {
  name: "ZoomFrame",
  data() {
    return {
      user_info:{},
      src: "",
      meetConfig: {},
      signature: {}
    };
  },
  props: {
    nickname: String,
    // meetingId: String,
    passWord:String
  },
  created() {
     this.user_info = JSON.parse(localStorage.getItem('User_data')) || localStorage.getItem('gust_name');
    // Meeting config object
    this.meetConfig = {
      apiKey: API_KEY,
      apiSecret: API_SECRET,
      meetingNumber: this.$route.params.id,
      userName: this.user_info.name,
      passWord: this.$route.params.pwd,
      leaveUrl: window.location.origin,
      role: 1,
    };

    // Generate Signature function
    this.signature = 
    ZoomMtg.generateSignature({
      meetingNumber: this.meetConfig.meetingNumber,
      apiKey: this.meetConfig.apiKey,
      apiSecret: this.meetConfig.apiSecret,
      role: this.meetConfig.role,
      success: function(res) {
        // eslint-disable-next-line
        console.log("success signature: " + res.result);
      }
    });
// coordinatoremail
    ZoomMtg.init({
      leaveUrl: "http://www.zoom.us",
      isSupportAV: true,
      success: () => {
        ZoomMtg.join({
          meetingNumber: this.meetConfig.meetingNumber,
          userName: this.meetConfig.userName,
          signature: this.signature,
          apiKey: this.meetConfig.apiKey,
          userEmail:'anwarsaeed1@yahoo.com',
          passWord: this.meetConfig.passWord,
          success: function(res) {
            // eslint-disable-next-line
            console.log("join meeting success");
          },
          error: function(res) {
            // eslint-disable-next-line
            console.log(res);
          }
        });
      },
      error: function(res) {
        // eslint-disable-next-line
        console.log(res);
      }
    });
  
  },

  mounted: function() {}

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
